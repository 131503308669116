.login-form-container {
    background: #00d080;
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form-container .bg-login-img {
    position: absolute;
    bottom: 20px;
    width: 70vw;
    z-index: 1;
}
.login-form {
    background: whitesmoke;
    width: 380px;
    height: 380px;
    padding: 30px;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;


}
.login-form-input {
    margin-top: 35px;
}
.login-logo-container img {
    height: 60px;
}
.login-logo-container strong {
    font-size: 28px;
}
.login-button {
    background-color: #00d080!important;
    border-radius: 6px!important;
    border: none!important;
    font-weight: bold;
    font-size: 18px;
    border: none;
    margin-top: 50px;
  }