@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap);
body {
  font-family: 'Ubuntu', sans-serif!important;
  overflow-x: hidden;
}
.main-container {
  background: whitesmoke;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
.content-container {
  height: 90vh;
  overflow: scroll;
  padding-bottom: 50px;
}
.App {
  text-align: center;
  overflow: hidden;

}
.footer {
  /* height: 6vh; */
  overflow: hidden;
  background: white;
  border-top: 1px solid grey;
  margin-top: 20px;
  height: 5vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: left;
}
.footer small {
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1px;
  margin-left: 8px;
}
.footer-logo-arqbag {
  height: 100%;
}
.footer img {
  height: 100%;
}
.footer .footer-right {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5vh;
}
.footer-right img {
  margin-left: 4px;
}

.navbar {
  background: white;
  z-index: 4;
}
.nav-container {
  /* height: 9vh; */
}
.add-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  width: 280px;
  background: white;
  border-radius: 6px;
}
.espais-selector-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.select-container {
  display: flex;
}
.select-container .add-input {
  background: #00d080;
  border-radius: 0 6px 6px 0;
  font-weight: bold;
  font-size: 18px;
  border: none;
  width: 20px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 2px;
}
.select-container select {
  border-radius: 6px 0 0 6px;
}
.select-container select:focus {
  border-color: #00d080;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%);
}
.form-control:focus {
  border-color: #00d080!important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
select.decorated option:hover {
  box-shadow: 0 0 10px 100px #2c3133 inset;
}
.form-container label {
  font-weight: bold;
  font-size: 18px;
}
.form-container .icon-select {
  height: 50px;
  width: auto;
  margin: 10px;
}
.question-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}
.question-container .nom-group {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.question-text {
  color: rgb(66, 66, 66);
  /* margin-top: 10px; */
  margin-bottom: 20px;
}
.question-text h1 {
  margin-top: 0px;
}
.list-container {
display: flex;
flex-direction: column;
justify-content: left;
align-items: flex-start;
}
.list-container .items-container {
  width: 100%;
}
.list-container .items-container .item {
  background: #00d080;
  width: 100%;
  padding: 5px 10px;
  margin-top: 5px;
  color: black;
  border-radius: 6px;
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.list-container .items-container .item form {
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  flex-direction: column;
}
.espai-input-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 20px;
  justify-content: left;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
}
.espai-title {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}
.list-container .items-container .item input {
  width: 100%;
}
.list-container .items-container .item button {
  background: #00d080;
  border: none;
}
.list-container .items-container .item button img {
  width: 16px;
}
.list-container .list-title {
  margin-top: 6px;
  color: grey;
}

.input-button {
  background-color: #00d080!important;
  border-radius: 0 6px 6px 0!important;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
}
.general-button {
  background-color: #00d080!important;
  border-radius: 6px;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;
}
.general-button:focus {
  border-color: #00d080!important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.video-button {
  background-color: #00d080!important;
  border-radius: 6px;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
  text-decoration: none;
  padding: 10px;
  color: #fff;
}
.video-button:focus {
  border-color: #00d080!important;
  text-decoration: none;
  color: whitesmoke;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.video-button:hover {
  border-color: #00d080!important;
  text-decoration: none;
  color: whitesmoke;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.explanation-text {
  color: rgb(167, 167, 167);
}
.explanation-text .hightlight {
  color:#00d080;
}
.text-recomendations .hightlight {
  color:#00d080;
}
.form-margin {
margin: 20px 0;
}
.instructions-text {
  margin: 0 auto;
  max-width: 800px;
  font-size: 22px;
  color: rgb(167, 167, 167);
  line-height: 26px;
}
.instructions-text .hightlight {
  color:#00d080;
}
.main-image-container img {
  max-width: 34vw;
}
.leyenda-btn {
  color: gray;
  margin-top: 5px;
}
@media only screen and (max-width: 600px) {
  .main-image-container img {
    max-width: 80vw;
  }
}
.img-vignette {
  height: 200px;
}
.mesure-container {
  padding: 20px;
  margin: 20px;
  width: 220px;
  background: white;
  border-radius: 6px;
}
.mesure-result-container {
  width: 80px;
  padding: 0 0;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 22px;
  border-bottom: 2px solid #00d080;
}
.mesure-result-container small {
  font-size: 16px;
  color: ;
}
.mesure-grid-container {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.title-icon {
  height: 72px;
  margin-left: 8px;
}
.title {
  margin-top: 30px;
}


@-webkit-keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}


@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.tipus-container .option-input {
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #00d080;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  z-index: 1000;
}
.tipus-container .option-input:hover {
  background: #04b672;
}
.tipus-container .option-input:checked {
  background: #00d080;
}
.tipus-container .option-input:checked::before {
  height: 40px;
  width: 40px;
  position: absolute;
  content: '✔';
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
}
.tipus-container .option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #00d080;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.tipus-container .option-input.radio {
  border-radius: 50%;
}
.tipus-container .option-input.radio::after {
  border-radius: 50%;
}
.tipus-container .flex-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.tipus-container .item {
  width: 180px;
}
.tipus-container label {
  padding: 20px;
}
.ubicacio-container button {
  margin-bottom: 30px;
}
.ubicacio-container button img {
  height: 20px;
  margin-right: 10px;
}
.ubicacio-container form {
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  margin-top: 40px;
}
.ubicacio-container .dos-col {
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}
.date-time label {
  color:grey;
  font-size: 14px;
}
.credits-item {
  margin-top: 10px
}
.credits-container {
  display: flex;
  justify-content: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  margin-top: 40px;
}
.credits-title {
  font-size: 8px;
  color: gray;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.credits-nombre {
  color: #2c3133;
}
.table-container {
  margin-top: 20px;
  padding: 10px;
}
.sumary-container {
  color: #2c3133;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .sumary-container {
    padding: 50px 50px;
  }
}
@media only screen and (min-width: 600px) {
  /* for tablets: */
  .sumary-container {
    padding: 50px 50px;
  }
}
@media only screen and (min-width: 768px) {
  .sumary-container {
    padding: 50px 380px;
    color: #2c3133;
  }
}


.semafors-container {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
.espais-semafor-container {
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}
.circle {
    border-radius: 50%;
    margin: 10px;
    border: 1px solid rgb(48, 48, 48);

}
.circle-border {
    border-radius: 50%;
    border: 1px solid rgb(48, 48, 48);
    margin: 4px;
}
.circle-border-big-grey {
    border-radius: 50%;
    border: 2px solid rgb(48, 48, 48);
    margin: 4px;
}
.circle-border-big-yellow {
    border-radius: 50%;
    border: 2.5px dashed #F0D500;
    margin: 4px;
}

.big {
    height: 60px;
    width: 60px;
}
.small {
    height: 30px;
    width: 30px;
    margin: 5px;
}
.green {
    background: #2dc937;
}
.yellow {
    background: #e7b416;
}
.red {
    background: #cc3232;
    color: whitesmoke;
}
.grey {
    background: #d8d8d8;
}
.circle-frame {
    border: 1px solid rgb(48, 48, 48);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    padding: 20px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: white;
}
.circle-frame-big {
    border: 2px solid rgb(48,48,48);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    padding: 20px;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: white;
}
.semafor-frame {
    width: 240px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.general {
    margin-top: 40px;
}


.background-diagonal {
    background: repeating-linear-gradient(
        45deg,
        grey,
        white,
        grey,
        white
      );
}
.circle-ani {
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}
.blink {
    -webkit-animation: blink 1.5s linear infinite;
            animation: blink 1.5s linear infinite;
}

@-webkit-keyframes spin { 
  100% { 
    -webkit-transform: rotateZ(360deg); 
            transform: rotateZ(360deg);
  }
}

@keyframes spin { 
  100% { 
    -webkit-transform: rotateZ(360deg); 
            transform: rotateZ(360deg);
  }
}
@-webkit-keyframes blink{
    0%{opacity: .3;}
    20%{opacity: .5;}
    100%{opacity: 1;}
    }
@keyframes blink{
    0%{opacity: .3;}
    20%{opacity: .5;}
    100%{opacity: 1;}
    }







.circle-output {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
}
.green-output {
    background: #01e901;
}
.yellow-output {
    background: rgb(255, 230, 0);
}
.red-output {
    background: red;
}
.output-container {
    display: grid;
    grid-template-columns: 1fr 1fr 100px 100px 1fr 1fr 1fr;
    width: 60vw;
    align-items: center;
    border-bottom: 1px solid grey;
}
.output-container p {
    padding: 0;
    margin: 0;
}
.output-container .output-title {
    font-weight: bold;
    padding-bottom: 8px;
    padding-top: 10px;
    text-align: left;
}
.output-container .output-alias {
    padding-bottom: 8px;
    padding-top: 10px;
    text-align: left;
}
.output-container .output-metres {
    padding-left: 10px;
    text-align: left;
}
.container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.outputs-all-container {
    margin-top: 40px;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 250px));
    grid-gap: 16px;
    justify-content: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.outputs-all-container .header-table {
    display: grid;
    grid-template-columns: 1fr 1fr 100px 100px 1fr 1fr 1fr; 
    width: 60vw;
    color: gray;
}
.outputs-all-container .header-table {
    padding: 0;
    margin: 0;
}
.outputs-all-container .header-table .header-margin-left {
    padding-left: 10px;
}
.outputs-all-container .header-table:first-child {
    text-align: left;
}
.verde { 
    color: white;
    background: #01e901;
}
.amarillo { 
    background: #e7d916;
    color: white;
}
.rojo { 
    background: tomato;
    color: white; 
}
.metros-small-label {
    color:grey;
}
.text-recomendations {
    max-width: 1000px;
    padding: 120px;
    color: rgb(128, 128, 128);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.text-recomendations .paraph strong {
    color: rgb(66, 66, 66);
}

.nav-bar-style {
    border-bottom: 1px solid grey;
}
.nav-bar-style a {
    text-decoration: none;
    color: black;
}
.login-form-container {
    background: #00d080;
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form-container .bg-login-img {
    position: absolute;
    bottom: 20px;
    width: 70vw;
    z-index: 1;
}
.login-form {
    background: whitesmoke;
    width: 380px;
    height: 380px;
    padding: 30px;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;


}
.login-form-input {
    margin-top: 35px;
}
.login-logo-container img {
    height: 60px;
}
.login-logo-container strong {
    font-size: 28px;
}
.login-button {
    background-color: #00d080!important;
    border-radius: 6px!important;
    border: none!important;
    font-weight: bold;
    font-size: 18px;
    border: none;
    margin-top: 50px;
  }
