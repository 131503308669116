@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
body {
  font-family: 'Ubuntu', sans-serif!important;
  overflow-x: hidden;
}
.main-container {
  background: whitesmoke;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
.content-container {
  height: 90vh;
  overflow: scroll;
  padding-bottom: 50px;
}
.App {
  text-align: center;
  overflow: hidden;

}
.footer {
  /* height: 6vh; */
  overflow: hidden;
  background: white;
  border-top: 1px solid grey;
  margin-top: 20px;
  height: 5vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: left;
}
.footer small {
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1px;
  margin-left: 8px;
}
.footer-logo-arqbag {
  height: 100%;
}
.footer img {
  height: 100%;
}
.footer .footer-right {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5vh;
}
.footer-right img {
  margin-left: 4px;
}

.navbar {
  background: white;
  z-index: 4;
}
.nav-container {
  /* height: 9vh; */
}
.add-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  width: 280px;
  background: white;
  border-radius: 6px;
}
.espais-selector-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.select-container {
  display: flex;
}
.select-container .add-input {
  background: #00d080;
  border-radius: 0 6px 6px 0;
  font-weight: bold;
  font-size: 18px;
  border: none;
  width: 20px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 2px;
}
.select-container select {
  border-radius: 6px 0 0 6px;
}
.select-container select:focus {
  border-color: #00d080;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%);
}
.form-control:focus {
  border-color: #00d080!important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
select.decorated option:hover {
  box-shadow: 0 0 10px 100px #2c3133 inset;
}
.form-container label {
  font-weight: bold;
  font-size: 18px;
}
.form-container .icon-select {
  height: 50px;
  width: auto;
  margin: 10px;
}
.question-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}
.question-container .nom-group {
  display: flex;
  column-gap: 10px;
}
.question-text {
  color: rgb(66, 66, 66);
  /* margin-top: 10px; */
  margin-bottom: 20px;
}
.question-text h1 {
  margin-top: 0px;
}
.list-container {
display: flex;
flex-direction: column;
justify-content: left;
align-items: flex-start;
}
.list-container .items-container {
  width: 100%;
}
.list-container .items-container .item {
  background: #00d080;
  width: 100%;
  padding: 5px 10px;
  margin-top: 5px;
  color: black;
  border-radius: 6px;
  display: flex;
  column-gap: 10px;
}
.list-container .items-container .item form {
  display: flex;
  column-gap: 5px;
  flex-direction: column;
}
.espai-input-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 20px;
  justify-content: left;
  column-gap: 2px;
}
.espai-title {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}
.list-container .items-container .item input {
  width: 100%;
}
.list-container .items-container .item button {
  background: #00d080;
  border: none;
}
.list-container .items-container .item button img {
  width: 16px;
}
.list-container .list-title {
  margin-top: 6px;
  color: grey;
}

.input-button {
  background-color: #00d080!important;
  border-radius: 0 6px 6px 0!important;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
}
.general-button {
  background-color: #00d080!important;
  border-radius: 6px;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;
}
.general-button:focus {
  border-color: #00d080!important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.video-button {
  background-color: #00d080!important;
  border-radius: 6px;
  border: none!important;
  font-weight: bold;
  font-size: 18px;
  border: none;
  text-decoration: none;
  padding: 10px;
  color: #fff;
}
.video-button:focus {
  border-color: #00d080!important;
  text-decoration: none;
  color: whitesmoke;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.video-button:hover {
  border-color: #00d080!important;
  text-decoration: none;
  color: whitesmoke;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 208 128 / 25%)!important;
}
.explanation-text {
  color: rgb(167, 167, 167);
}
.explanation-text .hightlight {
  color:#00d080;
}
.text-recomendations .hightlight {
  color:#00d080;
}
.form-margin {
margin: 20px 0;
}
.instructions-text {
  margin: 0 auto;
  max-width: 800px;
  font-size: 22px;
  color: rgb(167, 167, 167);
  line-height: 26px;
}
.instructions-text .hightlight {
  color:#00d080;
}
.main-image-container img {
  max-width: 34vw;
}
.leyenda-btn {
  color: gray;
  margin-top: 5px;
}
@media only screen and (max-width: 600px) {
  .main-image-container img {
    max-width: 80vw;
  }
}
.img-vignette {
  height: 200px;
}
.mesure-container {
  padding: 20px;
  margin: 20px;
  width: 220px;
  background: white;
  border-radius: 6px;
}
.mesure-result-container {
  width: 80px;
  padding: 0 0;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 22px;
  border-bottom: 2px solid #00d080;
}
.mesure-result-container small {
  font-size: 16px;
  color: ;
}
.mesure-grid-container {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.title-icon {
  height: 72px;
  margin-left: 8px;
}
.title {
  margin-top: 30px;
}


@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.tipus-container .option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #00d080;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  z-index: 1000;
}
.tipus-container .option-input:hover {
  background: #04b672;
}
.tipus-container .option-input:checked {
  background: #00d080;
}
.tipus-container .option-input:checked::before {
  height: 40px;
  width: 40px;
  position: absolute;
  content: '✔';
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
}
.tipus-container .option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #00d080;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.tipus-container .option-input.radio {
  border-radius: 50%;
}
.tipus-container .option-input.radio::after {
  border-radius: 50%;
}
.tipus-container .flex-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.tipus-container .item {
  width: 180px;
}
.tipus-container label {
  padding: 20px;
}
.ubicacio-container button {
  margin-bottom: 30px;
}
.ubicacio-container button img {
  height: 20px;
  margin-right: 10px;
}
.ubicacio-container form {
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  margin-top: 40px;
}
.ubicacio-container .dos-col {
  display: flex;
  column-gap: 5px;
}
.date-time label {
  color:grey;
  font-size: 14px;
}
.credits-item {
  margin-top: 10px
}
.credits-container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 40px;
}
.credits-title {
  font-size: 8px;
  color: gray;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.credits-nombre {
  color: #2c3133;
}
.table-container {
  margin-top: 20px;
  padding: 10px;
}
.sumary-container {
  color: #2c3133;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .sumary-container {
    padding: 50px 50px;
  }
}
@media only screen and (min-width: 600px) {
  /* for tablets: */
  .sumary-container {
    padding: 50px 50px;
  }
}
@media only screen and (min-width: 768px) {
  .sumary-container {
    padding: 50px 380px;
    color: #2c3133;
  }
}

