.circle-output {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
}
.green-output {
    background: #01e901;
}
.yellow-output {
    background: rgb(255, 230, 0);
}
.red-output {
    background: red;
}
.output-container {
    display: grid;
    grid-template-columns: 1fr 1fr 100px 100px 1fr 1fr 1fr;
    width: 60vw;
    align-items: center;
    border-bottom: 1px solid grey;
}
.output-container p {
    padding: 0;
    margin: 0;
}
.output-container .output-title {
    font-weight: bold;
    padding-bottom: 8px;
    padding-top: 10px;
    text-align: left;
}
.output-container .output-alias {
    padding-bottom: 8px;
    padding-top: 10px;
    text-align: left;
}
.output-container .output-metres {
    padding-left: 10px;
    text-align: left;
}
.container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.outputs-all-container {
    margin-top: 40px;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 250px));
    grid-gap: 16px;
    justify-content: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.outputs-all-container .header-table {
    display: grid;
    grid-template-columns: 1fr 1fr 100px 100px 1fr 1fr 1fr; 
    width: 60vw;
    color: gray;
}
.outputs-all-container .header-table {
    padding: 0;
    margin: 0;
}
.outputs-all-container .header-table .header-margin-left {
    padding-left: 10px;
}
.outputs-all-container .header-table:first-child {
    text-align: left;
}
.verde { 
    color: white;
    background: #01e901;
}
.amarillo { 
    background: #e7d916;
    color: white;
}
.rojo { 
    background: tomato;
    color: white; 
}
.metros-small-label {
    color:grey;
}
.text-recomendations {
    max-width: 1000px;
    padding: 120px;
    color: rgb(128, 128, 128);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.text-recomendations .paraph strong {
    color: rgb(66, 66, 66);
}
